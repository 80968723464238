import React from 'react'
import { Link } from "gatsby"


const Footer = () => (
<footer  class="site-footer">
    <ul>
    <p>© 2022 GetRevolut. All Rights Reserved.</p>
     </ul>
</footer>
)

export default Footer